import {
  AlertOutlined,
  CalculatorOutlined,
  ChromeOutlined,
  CloudOutlined,
  ContainerOutlined,
  DollarOutlined,
  FileDoneOutlined,
  GlobalOutlined,
  GoogleOutlined,
  HeatMapOutlined,
  LinkOutlined,
  LockOutlined,
  LogoutOutlined,
  MailOutlined,
  MessageOutlined,
  PhoneOutlined,
  RiseOutlined,
  SafetyOutlined,
  SearchOutlined,
  SettingOutlined,
  StarOutlined,
  TeamOutlined,
  ThunderboltOutlined,
  ToolOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { DefaultLoadable } from "./components/DefaultLoadable/index";
import { Roles } from "./lib/roles";
import { AuthenticationService } from "./services/authenticationService";

export class Routes {
  authenticationService: AuthenticationService = new AuthenticationService();

  getRoutes = (): RoutesMenuItem[] => [
    {
      path: "/campaigns",
      title: "Campaigns",
      icon: RiseOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Listinger,
        Roles.ListingerDev,
        Roles.Analyst,
        Roles.CustomerService,
        Roles.Specialist,
        Roles.SpecialistContact,
        Roles.Salesman,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Listinger,
        Roles.ListingerDev,
        Roles.Analyst,
        Roles.CustomerService,
        Roles.Specialist,
        Roles.SpecialistContact,
        Roles.Salesman,
      ]),
      component: DefaultLoadable(
        () => import("./scenes/Campaigns/CampaignsView")
      ),
    },
    {
      path: "/management-board",
      title: "Management",
      icon: ThunderboltOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
      ]),
      component: DefaultLoadable(
        () => import("./scenes/ManagementBoard/ManagementBoardView")
      ),
    },
    {
      path: "/website",
      title: "Website",
      icon: GlobalOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.ListingerDev,
        Roles.Analyst,
        Roles.CustomerService,
        Roles.Specialist,
        Roles.SpecialistContact,
        Roles.Developer,
        Roles.DeveloperPro,
        Roles.Salesman,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.ListingerDev,
        Roles.Analyst,
        Roles.CustomerService,
        Roles.Specialist,
        Roles.SpecialistContact,
        Roles.Developer,
        Roles.DeveloperPro,
        Roles.Salesman,
      ]),
      component: DefaultLoadable(() => import("./scenes/Website/WebsiteView")),
    },
    {
      path: "/listings",
      title: "Listings",
      icon: UnorderedListOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Listinger,
        Roles.ListingerDev,
        Roles.Analyst,
        Roles.Specialist,
        Roles.SpecialistContact,
        Roles.Salesman,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Listinger,
        Roles.ListingerDev,
        Roles.Analyst,
        Roles.Specialist,
        Roles.SpecialistContact,
        Roles.Salesman,
      ]),
      component: DefaultLoadable(() => import("./scenes/Listing/ListingView")),
    },
    {
      path: "/contacts",
      title: "Contacts",
      icon: PhoneOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.CustomerService,
        Roles.SpecialistContact,
      ]),
      component: DefaultLoadable(
        () => import("./scenes/Contacts/ContactsView")
      ),
    },
    {
      path: "/access",
      title: "Access",
      name: "websiteslookup",
      icon: LockOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Listinger,
        Roles.ListingerDev,
        Roles.Analyst,
        Roles.CustomerService,
        Roles.Specialist,
        Roles.SpecialistContact,
        Roles.Texter,
        Roles.Developer,
        Roles.DeveloperPro,
        Roles.Operator,
        Roles.Salesman,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Listinger,
        Roles.ListingerDev,
        Roles.Analyst,
        Roles.CustomerService,
        Roles.Specialist,
        Roles.SpecialistContact,
        Roles.Texter,
        Roles.Developer,
        Roles.DeveloperPro,
        Roles.Operator,
        Roles.Salesman,
      ]),
      component: DefaultLoadable(() => import("./scenes/Access/AccessView")),
    },
    {
      title: "Tools",
      path: "/tools-menu",
      icon: ToolOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Listinger,
        Roles.ListingerDev,
        Roles.Analyst,
        Roles.CustomerService,
        Roles.Specialist,
        Roles.SpecialistContact,
        Roles.Texter,
        Roles.Developer,
        Roles.DeveloperPro,
        Roles.Operator,
        Roles.Salesman,
      ]),
      subMenuItems: [
        {
          path: "/simulator",
          subMenuPath: "/simulator",
          title: "Simulator",
          icon: SearchOutlined,
          showInMenu: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.CampaignManager,
            Roles.Listinger,
            Roles.ListingerDev,
            Roles.Analyst,
            Roles.CustomerService,
            Roles.Specialist,
            Roles.SpecialistContact,
            Roles.Texter,
            Roles.Developer,
            Roles.DeveloperPro,
            Roles.Operator,
            Roles.Salesman,
          ]),
          available: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.CampaignManager,
            Roles.Listinger,
            Roles.ListingerDev,
            Roles.Analyst,
            Roles.CustomerService,
            Roles.Specialist,
            Roles.SpecialistContact,
            Roles.Texter,
            Roles.Developer,
            Roles.DeveloperPro,
            Roles.Operator,
            Roles.Salesman,
          ]),
        },
        {
          path: "/sms-gateway",
          subMenuPath: "/sms-gateway",
          title: "SMS Gateway",
          name: "user",
          icon: MessageOutlined,
          showInMenu: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.CampaignManager,
            Roles.CustomerService,
            Roles.SpecialistContact,
            Roles.Texter,
          ]),
          available: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.CampaignManager,
            Roles.CustomerService,
            Roles.SpecialistContact,
            Roles.Texter,
          ]),
        },
        {
          path: "/pyramid",
          subMenuPath: "/pyramid",
          title: "Pyramid",
          name: "pyramid",
          icon: HeatMapOutlined,
          showInMenu: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.CampaignManager,
            Roles.Listinger,
            Roles.Analyst,
            Roles.CustomerService,
            Roles.Operator,
            Roles.Salesman,
          ]),
          available: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.CampaignManager,
            Roles.Listinger,
            Roles.Analyst,
            Roles.CustomerService,
            Roles.Operator,
            Roles.Salesman,
          ]),
          component: DefaultLoadable(
            () => import("./scenes/Pyramid/PyramidView")
          ),
        },
        {
          path: "/reviews",
          subMenuPath: "/reviews",
          title: "Reviews",
          icon: StarOutlined,
          showInMenu: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.CampaignManager,
            Roles.Specialist,
            Roles.SpecialistContact,
          ]),
          available: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.CampaignManager,
            Roles.Specialist,
            Roles.SpecialistContact,
          ]),
        },
        {
          path: "/integrations",
          subMenuPath: "/integrations",
          title: "Integrations",
          icon: GoogleOutlined,
          showInMenu: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.CampaignManager,
            Roles.ListingerDev,
            Roles.Specialist,
            Roles.SpecialistContact,
          ]),
          available: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.CampaignManager,
            Roles.ListingerDev,
            Roles.Specialist,
            Roles.SpecialistContact,
          ]),
        },
        {
          path: "/accounting",
          subMenuPath: "/accounting",
          title: "Accounting",
          icon: DollarOutlined,
          showInMenu: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.CampaignManager,
          ]),
          available: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.CampaignManager,
          ]),
        },
        {
          path: "/accounting-history",
          subMenuPath: "/accounting-history",
          title: "Accounting History",
          icon: DollarOutlined,
          showInMenu: false,
          available: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.CampaignManager,
          ]),
        },
        {
          path: "/inbound-calls",
          subMenuPath: "/inbound-calls",
          title: "Inbound Calls",
          icon: PhoneOutlined,
          showInMenu: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.Salesman,
          ]),
          available: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.Salesman,
          ]),
        },
        {
          path: "/email-reports",
          subMenuPath: "/email-reports",
          title: "Email Reports",
          name: "Email Reports",
          icon: FileDoneOutlined,
          showInMenu: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.CampaignManager,
            Roles.Specialist,
            Roles.SpecialistContact,
          ]),
          available: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.CampaignManager,
            Roles.Specialist,
            Roles.SpecialistContact,
          ]),
        },
        {
          path: "/cost-per-lead",
          subMenuPath: "/cost-per-lead",
          title: "Cost Per Lead",
          name: "Cost Per Lead",
          icon: CalculatorOutlined,
          showInMenu: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.CampaignManager,
            Roles.Specialist,
            Roles.SpecialistContact,
          ]),
          available: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.CampaignManager,
            Roles.Specialist,
            Roles.SpecialistContact,
          ]),
        },
        {
          path: "/settings",
          subMenuPath: "/settings",
          title: "Settings",
          name: "settings",
          icon: SettingOutlined,
          showInMenu: this.authenticationService.isUserInRoles([Roles.Admin]),
          available: this.authenticationService.isUserInRoles([Roles.Admin]),
        },
      ],
      component: DefaultLoadable(
        () => import("./scenes/SubmenuView/SideMenuView")
      ),
    },
    {
      title: "Campaigns",
      path: "/campaigns",
      icon: AlertOutlined,
      isNotification: true,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Developer,
        Roles.DeveloperPro,
        Roles.Specialist,
        Roles.SpecialistContact,
      ]),
      subMenuItems: [
        {
          path: "/gmb-notif",
          subMenuPath: "/gmb-notif",
          title: "GMB Notifications",
          shortTitle: "GMB",
          name: "gmb-notif",
          icon: GoogleOutlined,
          showInMenu: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.CampaignManager,
          ]),
        },
        {
          path: "/web-notif",
          subMenuPath: "/web-notif",
          title: "Web Monitoring",
          shortTitle: "Web",
          name: "web-notif",
          icon: GlobalOutlined,
          showInMenu: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.CampaignManager,
            Roles.Developer,
            Roles.DeveloperPro,
          ]),
        },
        {
          path: "/gps-notif",
          subMenuPath: "/gps-notif",
          title: "Google Page Speed Monitoring",
          shortTitle: "GPS",
          name: "gps-notif",
          icon: ChromeOutlined,
          showInMenu: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.CampaignManager,
            Roles.Developer,
            Roles.DeveloperPro,
          ]),
        },
        {
          path: "/website-agent-job-log",
          subMenuPath: "/website-agent-job-log",
          title: "Webiste Agent Job Log",
          shortTitle: "Jobs",
          name: "website-agent-job-log",
          icon: ContainerOutlined,
          showInMenu: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.CampaignManager,
            Roles.Specialist,
            Roles.SpecialistContact,
            Roles.Developer,
            Roles.DeveloperPro,
          ]),
        },
        {
          path: "/campaign-notification",
          subMenuPath: "/campaign-notification",
          title: "Campaign Notifications",
          shortTitle: "CamNotif",
          name: "campaign-notification",
          icon: DollarOutlined,
          showInMenu: this.authenticationService.isUserInRoles([
            Roles.Admin,
            Roles.CampaignManager,
          ]),
        },
      ],
    },
    {
      path: "/gmb-notif",
      title: "GMB Notifications",
      name: "gmb-notif",
      icon: AlertOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
      ]),
      component: DefaultLoadable(
        () =>
          import("./scenes/Notifications/GMBNotification/GMBNotificationView")
      ),
      routeOnly: true,
    },
    {
      path: "/web-notif",
      title: "Web Monitoring",
      name: "web-notif",
      icon: AlertOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Developer,
        Roles.DeveloperPro,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Developer,
        Roles.DeveloperPro,
      ]),
      component: DefaultLoadable(
        () =>
          import("./scenes/Notifications/WebNotification/WebNotificationView")
      ),
      routeOnly: true,
    },
    {
      path: "/gps-notif",
      title: "GPS Monitoring",
      name: "gps-notif",
      icon: AlertOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Developer,
        Roles.DeveloperPro,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Developer,
        Roles.DeveloperPro,
      ]),
      component: DefaultLoadable(
        () =>
          import(
            "./scenes/Notifications/GooglePageSpeedNotification/GooglePageSpeedNotificationView"
          )
      ),
      routeOnly: true,
    },
    {
      path: "/website-agent-job-log",
      title: "Website Agent Job Log",
      name: "website-agent-job-log",
      icon: AlertOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Specialist,
        Roles.SpecialistContact,
        Roles.Developer,
        Roles.DeveloperPro,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Specialist,
        Roles.SpecialistContact,
        Roles.Developer,
        Roles.DeveloperPro,
      ]),
      component: DefaultLoadable(
        () =>
          import(
            "./scenes/Notifications/WebsiteAgentJobLog/WebsiteAgentJobLogView"
          )
      ),
      routeOnly: true,
    },
    {
      path: "/campaign-notification",
      title: "Campaign Notification",
      name: "campaign-notification",
      icon: AlertOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
      ]),
      component: DefaultLoadable(
        () =>
          import(
            "./scenes/Notifications/CampaignNotification/CampaignNotificationView"
          )
      ),
      routeOnly: true,
    },
    {
      path: "/simulator",
      title: "Search Simulator",
      name: "simulator",
      icon: SearchOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Listinger,
        Roles.ListingerDev,
        Roles.Analyst,
        Roles.CustomerService,
        Roles.Specialist,
        Roles.SpecialistContact,
        Roles.Texter,
        Roles.Developer,
        Roles.DeveloperPro,
        Roles.Operator,
        Roles.Salesman,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Listinger,
        Roles.ListingerDev,
        Roles.Analyst,
        Roles.CustomerService,
        Roles.Specialist,
        Roles.SpecialistContact,
        Roles.Texter,
        Roles.Developer,
        Roles.DeveloperPro,
        Roles.Operator,
        Roles.Salesman,
      ]),
      routeOnly: true,
      component: DefaultLoadable(
        () => import("./scenes/SearchSimulator/SearchSimulator")
      ),
    },
    {
      path: "/sms-gateway",
      title: "SMS Gateway",
      name: "user",
      icon: MessageOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.CustomerService,
        Roles.SpecialistContact,
        Roles.Texter,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.CustomerService,
        Roles.SpecialistContact,
        Roles.Texter,
      ]),
      routeOnly: true,
      component: DefaultLoadable(
        () => import("./scenes/SmsGateway/SmsGatewayView")
      ),
    },
    {
      path: "/email-reports",
      title: "Email reports",
      icon: MailOutlined,
      routeOnly: true,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Specialist,
        Roles.SpecialistContact,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Specialist,
        Roles.SpecialistContact,
      ]),
      component: DefaultLoadable(
        () => import("./scenes/Reports/EmailReportView")
      ),
    },
    {
      path: "/cost-per-lead",
      title: "Cost per lead",
      icon: CalculatorOutlined,
      routeOnly: true,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Specialist,
        Roles.SpecialistContact,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Specialist,
        Roles.SpecialistContact,
      ]),
      component: DefaultLoadable(
        () => import("./scenes/Reports/CostPerLeadView")
      ),
    },
    {
      path: "/campaigns-list",
      title: "Camp.list.wip",
      icon: RiseOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.CustomerService,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.CustomerService,
      ]),
      routeOnly: true,
      component: DefaultLoadable(
        () => import("./scenes/CampaignList/CampaignListView")
      ),
    },
    {
      path: "/integrations",
      title: "Google Accounts",
      icon: GoogleOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Listinger,
        Roles.ListingerDev,
        Roles.Specialist,
        Roles.SpecialistContact,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Listinger,
        Roles.ListingerDev,
        Roles.Specialist,
        Roles.SpecialistContact,
      ]),
      routeOnly: true,
      component: DefaultLoadable(
        () => import("./scenes/Integrations/IntegrationsView")
      ),
    },
    {
      path: "/accounting",
      title: "Accounting",
      icon: DollarOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
      ]),
      routeOnly: true,
      component: DefaultLoadable(
        () => import("./scenes/Accounting/AccountingMainView")
      ),
    },
    {
      path: "/accounting-history",
      title: "Accounting History",
      icon: DollarOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
      ]),
      routeOnly: true,
      component: DefaultLoadable(
        () => import("./scenes/Accounting/AccountingHistoryLogView")
      ),
    },
    {
      path: "/inbound-calls",
      title: "Inbound Calls",
      icon: PhoneOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.Salesman,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.Salesman,
      ]),
      routeOnly: true,
      component: DefaultLoadable(
        () => import("./scenes/InboundCalls/InboundCallView")
      ),
    },
    {
      path: "/settings",
      title: "Settings",
      name: "settings",
      icon: SettingOutlined,
      showInMenu: this.authenticationService.isUserInRoles([Roles.Admin]),
      available: this.authenticationService.isUserInRoles([Roles.Admin]),
      routeOnly: true,
      component: DefaultLoadable(
        () => import("./scenes/Settings/SettingsView")
      ),
    },
    {
      path: "/users",
      title: "Users",
      name: "user",
      icon: TeamOutlined,
      showInMenu: this.authenticationService.isUserInRoles([Roles.Admin]),
      available: this.authenticationService.isUserInRoles([Roles.Admin]),
      routeOnly: true,
      component: DefaultLoadable(
        () => import("./scenes/Settings/User/UserView")
      ),
    },
    {
      path: "/form-messages",
      title: "Form Messages",
      name: "form-messages",
      icon: MessageOutlined,
      showInMenu: this.authenticationService.isUserInRoles([Roles.Admin]),
      available: this.authenticationService.isUserInRoles([Roles.Admin]),
      routeOnly: true,
      component: DefaultLoadable(
        () => import("./scenes/Settings/FormMessages/FormMessages")
      ),
    },
    {
      path: "/form-settings",
      title: "Form Settings",
      name: "form-settings",
      icon: MessageOutlined,
      showInMenu: this.authenticationService.isUserInRoles([Roles.Admin]),
      available: this.authenticationService.isUserInRoles([Roles.Admin]),
      routeOnly: true,
      component: DefaultLoadable(
        () => import("./scenes/Settings/FormMessages/FormSettings")
      ),
    },
    {
      path: "/review-begger",
      title: "Review begger bulk",
      name: "simulator",
      icon: SearchOutlined,
      showInMenu: this.authenticationService.isUserInRoles([Roles.Admin]),
      available: this.authenticationService.isUserInRoles([Roles.Admin]),
      routeOnly: true,
      component: DefaultLoadable(
        () => import("./scenes/Settings/ReviewBegger/ReviewBeggerBulkView")
      ),
    },
    {
      path: "/ivr-all-campaigns",
      title: "All Campaigns View - IVR",
      name: "simulator",
      icon: SearchOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
      ]),
      routeOnly: true,
      component: DefaultLoadable(
        () => import("./scenes/AllIvrCampaigns/AllIvrCampaignsView")
      ),
    },
    {
      path: "/email-settings",
      title: "Email",
      icon: MailOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Specialist,
        Roles.SpecialistContact,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Specialist,
        Roles.SpecialistContact,
      ]),
      routeOnly: true,
      component: DefaultLoadable(
        () => import("./scenes/EmailSettings/EmailSettings")
      ),
    },
    {
      path: "/mail-settings-global",
      title: "Email",
      icon: MailOutlined,
      showInMenu: this.authenticationService.isUserInRoles([Roles.Admin]),
      available: this.authenticationService.isUserInRoles([Roles.Admin]),
      routeOnly: true,
      component: DefaultLoadable(
        () => import("./scenes/EmailSettingsGlobal/EmailSettingsGlobal")
      ),
    },
    {
      path: "/service",
      title: "Service",
      name: "service",
      icon: MessageOutlined,
      showInMenu: this.authenticationService.isUserInRoles([Roles.Admin]),
      available: this.authenticationService.isUserInRoles([Roles.Admin]),
      component: DefaultLoadable(
        () => import("./scenes/Settings/Service/ServiceView")
      ),
      routeOnly: true,
    },
    {
      path: "/industry",
      title: "Industries",
      name: "industries",
      icon: MessageOutlined,
      showInMenu: this.authenticationService.isUserInRoles([Roles.Admin]),
      available: this.authenticationService.isUserInRoles([Roles.Admin]),
      component: DefaultLoadable(
        () => import("./scenes/Settings/Industry/IndustryView")
      ),
      routeOnly: true,
    },
    {
      path: "/country",
      title: "Country",
      name: "country",
      icon: MessageOutlined,
      showInMenu: this.authenticationService.isUserInRoles([Roles.Admin]),
      available: this.authenticationService.isUserInRoles([Roles.Admin]),
      component: DefaultLoadable(
        () => import("./scenes/Settings/Country/CountryView")
      ),
      routeOnly: true,
    },
    {
      path: "/client-source",
      title: "Client Source",
      name: "client-source",
      icon: MessageOutlined,
      showInMenu: this.authenticationService.isUserInRoles([Roles.Admin]),
      available: this.authenticationService.isUserInRoles([Roles.Admin]),
      component: DefaultLoadable(
        () => import("./scenes/Settings/ClientSource/ClientSourceView")
      ),
      routeOnly: true,
    },
    {
      path: "/backlink-type",
      title: "Backlink Type",
      name: "backlink-type",
      icon: LinkOutlined,
      showInMenu: this.authenticationService.isUserInRoles([Roles.Admin]),
      available: this.authenticationService.isUserInRoles([Roles.Admin]),
      component: DefaultLoadable(
        () => import("./scenes/Settings/BacklinkType/BacklinkTypeView")
      ),
      routeOnly: true,
    },
    {
      path: "/cloudflare-firewall-rule",
      title: "Cloudflare Firewall Rule",
      name: "cloudflare-firewall-rule",
      icon: CloudOutlined,
      showInMenu: this.authenticationService.isUserInRoles([Roles.Admin]),
      available: this.authenticationService.isUserInRoles([Roles.Admin]),
      component: DefaultLoadable(
        () =>
          import(
            "./scenes/Settings/CloudflareFirewallRule/CloudflareFirewallRule"
          )
      ),
      routeOnly: true,
    },
    {
      path: "/senderforscanning",
      title: "Sender For Scanning",
      name: "sender for scanning",
      icon: MessageOutlined,
      showInMenu: this.authenticationService.isUserInRoles([Roles.Admin]),
      available: this.authenticationService.isUserInRoles([Roles.Admin]),
      component: DefaultLoadable(
        () => import("./scenes/SenderForScanning/SenderForScanningView")
      ),
      routeOnly: true,
    },
    {
      path: "/everhour",
      title: "Everhour",
      name: "everhour",
      icon: MessageOutlined,
      showInMenu: this.authenticationService.isUserInRoles([Roles.Admin]),
      available: this.authenticationService.isUserInRoles([Roles.Admin]),
      component: DefaultLoadable(
        () => import("./scenes/Everhour/EverhourView")
      ),
      routeOnly: true,
    },
    {
      path: "/reviews",
      title: "Reviews",
      name: "reviews",
      icon: StarOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Specialist,
        Roles.SpecialistContact,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Specialist,
        Roles.SpecialistContact,
      ]),
      component: DefaultLoadable(() => import("./scenes/Review/ReviewView")),
      routeOnly: true,
    },
    {
      path: "/seo-gmb-scheduler",
      title: "SeoGmbScheduler",
      name: "seo-gmb-scheduler",
      icon: MessageOutlined,
      showInMenu: this.authenticationService.isUserInRoles([Roles.Admin]),
      available: this.authenticationService.isUserInRoles([Roles.Admin]),
      component: DefaultLoadable(
        () => import("./scenes/Settings/SeoGmbScheduler/SeoGmbSchedulerView")
      ),
      routeOnly: true,
    },
    {
      path: "/subscription-items",
      title: "SubscriptionItems",
      name: "subscription-items",
      icon: DollarOutlined,
      showInMenu: this.authenticationService.isUserInRoles([Roles.Admin]),
      available: this.authenticationService.isUserInRoles([Roles.Admin]),
      component: DefaultLoadable(
        () =>
          import("./scenes/Settings/SubscriptionItems/SubscriptionItemsView")
      ),
      routeOnly: true,
    },
    {
      path: "/review-profiles",
      title: "Review Profiles",
      name: "review-profiles",
      icon: DollarOutlined,
      showInMenu: this.authenticationService.isUserInRoles([Roles.Admin]),
      available: this.authenticationService.isUserInRoles([Roles.Admin]),
      component: DefaultLoadable(
        () => import("./scenes/Settings/ReviewProfile/ReviewProfileView")
      ),
      routeOnly: true,
    },
    {
      path: "/google-listing-categories",
      title: "GoogleListingsCategories",
      name: "google-listing-categories",
      icon: DollarOutlined,
      showInMenu: this.authenticationService.isUserInRoles([Roles.Admin]),
      available: this.authenticationService.isUserInRoles([Roles.Admin]),
      component: DefaultLoadable(
        () =>
          import(
            "./scenes/Settings/GoogleListingCategories/GoogleListingCategoriesView"
          )
      ),
      routeOnly: true,
    },
    {
      path: "/google-defender-settings",
      title: "GoogleDefenderSettings",
      name: "google-defender-settings",
      icon: SafetyOutlined,
      showInMenu: this.authenticationService.isUserInRoles([Roles.Admin]),
      available: this.authenticationService.isUserInRoles([Roles.Admin]),
      component: DefaultLoadable(
        () =>
          import(
            "./scenes/Settings/GoogleDefenderSettings/GoogleDefenderSettingsView"
          )
      ),
      routeOnly: true,
    },
    {
      path: "/pyramid",
      title: "Pyramid",
      name: "pyramid",
      icon: HeatMapOutlined,
      showInMenu: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Analyst,
        Roles.Listinger,
        Roles.CustomerService,
        Roles.Operator,
        Roles.Salesman,
      ]),
      available: this.authenticationService.isUserInRoles([
        Roles.Admin,
        Roles.CampaignManager,
        Roles.Analyst,
        Roles.Listinger,
        Roles.CustomerService,
        Roles.Operator,
        Roles.Salesman,
      ]),
      component: DefaultLoadable(() => import("./scenes/Pyramid/PyramidView")),
      routeOnly: true,
    },
    {
      path: "/logout",
      title: "Logout",
      name: "logout",
      icon: LogoutOutlined,
      showInMenu: false,
      component: DefaultLoadable(() => import("./scenes/Logout/Logout")),
    },
  ];
}

export interface RoutesMenuItem {
  path: string;
  subMenuPath?: string;
  title: string;
  icon: React.JSX.ElementType;
  showInMenu?: boolean;
  available?: boolean;
  component?: any;
  subMenuItems?: RoutesMenuItem[];
  name?: string;
  shortTitle?: string;
  routeOnly?: boolean;
  history?: any;
  description?: string;
  isNotification?: boolean;
}

export function isActiveMenu(route: RoutesMenuItem) {
  return findMenuItemByPath(route, window.location.pathname);
}

export function findMenuItemByPath(
  route: RoutesMenuItem,
  path: string
): RoutesMenuItem | undefined {
  if (route.path === path) {
    return route;
  }

  if (route.subMenuItems) {
    return route.subMenuItems.find((x) => x.subMenuPath == path);
  }

  return undefined;
}
