import { Badge } from "antd";
import { Link } from "react-router-dom";
import { RoutesMenuItem, isActiveMenu } from "../../routes";

const NotificationMenuElement = (props: {
  route: RoutesMenuItem;
  icon: React.JSX.ElementType;
  count: number;
  title?: string;
}) => {
  return !!props.route.subMenuPath ? (
    <Link
      to={props.route.subMenuPath}
      className={`${
        isActiveMenu(props.route) ? "active" : ""
      } generic-menu-item`}
    >
      <div className="icon-container">
        <props.icon
          style={{
            fontSize: "20px",
            borderBottom: isActiveMenu(props.route)
              ? "2px solid black"
              : "none",
          }}
        />
        <Badge
          offset={[15, -30]}
          count={props.count}
          key={`${props.route.subMenuPath}-badge`}
          style={{ position: "absolute", backgroundColor: "blue" }}
          overflowCount={9999}
        />
      </div>
      {!!props.title && <span className="d-inline">{props.title}</span>}
    </Link>
  ) : (
    <></>
  );
};

export default NotificationMenuElement;
